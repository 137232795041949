import { Routes } from '@angular/router';
import { autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { crmLayoutOutletRoute } from 'common-module/layout-v2';

import { roleGuard } from '~/shared/guards/role.guard';

export const appRoutes = {
  home: 'home',
  supplements: 'supplements',
  packagings: 'packagings',
  products: 'products',
  customers: 'customers',
  users: 'users',
  roles: 'roles',
  settings: 'settings',
} as const;

export const routes: Routes = [
  crmLayoutOutletRoute({
    path: 'app',
    canMatch: [autoLoginPartialRoutesGuard],
    children: [
      {
        path: appRoutes.home,
        loadChildren: () => import('./features/home/routes'),
      },
      {
        path: appRoutes.supplements,
        loadChildren: () => import('./features/supplements/routes'),
      },
      {
        path: appRoutes.packagings,
        loadChildren: () => import('./features/packagings/routes'),
      },
      {
        path: appRoutes.products,
        loadChildren: () => import('./features/products/routes'),
      },
      {
        path: appRoutes.customers,
        loadChildren: () => import('./features/customers/routes'),
      },
      {
        canMatch: [roleGuard(['Admin', 'SuperAdmin'])],
        path: appRoutes.users,
        loadChildren: () => import('./features/users/routes'),
      },
      {
        canMatch: [roleGuard(['SuperAdmin'])],
        path: appRoutes.roles,
        loadChildren: () => import('./features/roles/routes'),
      },
      {
        canMatch: [roleGuard(['Admin', 'SuperAdmin'])],
        path: appRoutes.settings,
        loadChildren: () => import('./features/settings/routes'),
      },
      { path: '**', redirectTo: 'home' },
    ],
  }),
  {
    path: 'callback',
    loadComponent: () => import('./features/callback/callback.component'),
  },
  {
    path: 'login',
    loadComponent: () => import('./features/login/login.component'),
  },
  { path: '**', redirectTo: 'app' },
];
