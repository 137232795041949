import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';

import { AuthService } from '~/api/auth/auth.service';

import { UserRole } from '../types/user.role';

/**
 * Role guard for routes
 */
export const roleGuard = (roles: UserRole[]) => () => {
  return inject(AuthService).userData$.pipe(
    map(({ role }) => {
      if (!role || role.length === 0) {
        return false;
      }

      if (typeof role === 'string') {
        return roles.includes(role);
      }

      return (
        roles.some((userRole) => role.some((role) => role === userRole)) ??
        false
      );
    }),
    catchError(() => of(true)),
  );
};
